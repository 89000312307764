import { getAPIRequest, postAPIRequest, putAPIRequest } from '../AxiosApis';

const baseRoute = 'osat';

/**
* @description Starts the process to scan the OSATs for updates
* @returns API Response returns Status Code
*/
export async function scanOsatsForUpdates() {
  return postAPIRequest(`${baseRoute}/scan`, {});
}

export async function getOSATList(includeDisabled) {
  const params = { includeDisabled };
  try {
    const response = await getAPIRequest(`${baseRoute}/osats`, params);
    return response;
  } catch (error) {
    return error;
  }
}

/**
* @description Get a password from a given osat ID.
* @param {string} osatId required, OSAT ID
* @returns API Response returns Status Code and the Password for the given OSAT ID
*/
export async function getOSATPassword(osatId) {
  try {
    const response = await getAPIRequest(`${baseRoute}/password/${osatId}`, {});
    return response;
  } catch (error) {
    return error;
  }
}

/**
* @description Registers a new factory in the system.
* @param {object} values required, body request to create an OSAT
* @returns API Response returns Status Code
*/
export async function createOSAT(values) {
  try {
    const response = await postAPIRequest(`${baseRoute}/create`, values);
    return response;
  } catch (error) {
    return error;
  }
}

/**
* @description Update a factory information in the system.
* @param {object} values required, body request to create an OSAT
* @returns API Response returns Status Code
*/
export async function updateOSAT(values) {
  try {
    const response = await putAPIRequest(`${baseRoute}`, values);
    return response;
  } catch (error) {
    return error;
  }
}
