import * as React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { convertToLocalDate } from '../../../../utils/utils';

const OPNHeadCells = [
  {
    field: 'skyhiGuid',
    numeric: false,
    disablePadding: true,
    headerName: 'SkyHI ID',
    description: 'SkyHI ID',
    sortable: true,
    minWidth: 330,
    renderCell: (params) => (
      <Tooltip title={params.row.skyhiGuid}>
        <span className="table-cell-trucate">{params.row.skyhiGuid}</span>
      </Tooltip>
    ),
  },
  {
    field: 'opnId',
    numeric: false,
    disablePadding: true,
    headerName: 'Part Num',
    description: 'Part Num',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.row.opnId}>
        <Button
          size="small"
        >
          {params.value}
        </Button>
      </Tooltip>
    ),
  },
  {
    field: 'createdTime',
    numeric: true,
    disablePadding: false,
    headerName: 'Create Date',
    description: 'Create Date',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={convertToLocalDate(params.row.createdTime)}>
        <span className="table-cell-trucate">{convertToLocalDate(params.row.createdTime)}</span>
      </Tooltip>
    ),
  },
  {
    field: 'partFamilyName',
    numeric: true,
    disablePadding: false,
    headerName: 'Part Family',
    description: 'Part Family',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.row.partFamilyName}>
        <span className="table-cell-trucate">{params.row.partFamilyName}</span>
      </Tooltip>
    ),
  },
  {
    field: 'basePartName',
    numeric: true,
    disablePadding: false,
    headerName: 'Base Part',
    description: 'Base Part',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.row.basePartName}>
        <span className="table-cell-trucate">{params.row.basePartName}</span>
      </Tooltip>
    ),
  },
  {
    field: 'requestType',
    numeric: true,
    disablePadding: false,
    headerName: 'Request Type',
    description: 'Request Type',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.row.requestType}>
        <span className="table-cell-trucate">{params.row.requestType}</span>
      </Tooltip>
    ),
  },
  {
    field: 'opnStatus',
    numeric: true,
    disablePadding: false,
    headerName: 'Status',
    description: 'Status',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.row.opnStatus}>
        <span className="table-cell-trucate">{params.row.opnStatus}</span>
      </Tooltip>
    ),
  },
  {
    field: 'updatedTime',
    numeric: true,
    disablePadding: false,
    headerName: 'Last Update',
    description: 'Last Update',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.row.updatedTime}>
        <span className="table-cell-trucate">{params.row.updatedTime}</span>
      </Tooltip>
    ),
  },
  {
    field: 'createEmail',
    numeric: true,
    disabledPadding: false,
    headerName: 'Creator',
    description: 'Creator',
    sortable: true,
    minWidth: 250,
    renderCell: (params) => (
      <Tooltip title={params.row.createEmail}>
        <span className="table-cell-trucate">{params.row.createEmail}</span>
      </Tooltip>
    ),
  },
];

export default OPNHeadCells;
