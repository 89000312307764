import React from 'react';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import {
  Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const StyledForm = styled(FormControl)(({ theme }) => ({
  width: '90%',
  margin: theme.spacing(1),
}));

const StyledTextBox = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(1),
}));

const StyledFormCtrl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: '20%',
  margin: theme.spacing(1),
  padding: theme.spacing(1),
  float: 'right',
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  width: '20%',
  margin: theme.spacing(1),
  padding: theme.spacing(1),
  float: 'right',
}));

export default function FactoryForm(props) {
  const {
    setOpenPopup,
    isUpdate,
    setValues,
    values,
    callCreateOsatAPI,
    callUpdateOsatAPI,
    setErrors,
    errors,
    setLoading,
    loading,
  } = props;

  const validate = () => {
    const temp = {};
    const ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const ftpPortFormat = /^(6553[0-5]|655[0-2][0-9]|65[0-4][0-9]{2}|6[0-4][0-9]{3}|[0-5]?([0-9]){0,3}[0-9])$/;
    temp.ftpPort = (ftpPortFormat).test(values.ftpPort) ? '' : 'Invalid FTP Port';
    temp.reservedHubIpPrimary = (ipformat).test(values.reservedHubIpPrimary) ? '' : 'Invalid IP address!';
    temp.reservedHubIpBackup = (ipformat).test(values.reservedHubIpBackup) ? '' : 'Invalid IP address!';
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  // Call the services here
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      // If update call update service
      if (!isUpdate) {
        callCreateOsatAPI();
      } else {
        callUpdateOsatAPI();
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <StyledForm>
          <StyledTextBox required variant="outlined" label="OSAT Name" name="name" placeholder="DEV OSAT - SFTP" value={values.name} onChange={handleInputChange} fullWidth error={!!errors.name} helperText={errors.name} inputProps={{ tabIndex: '1' }} />
          <StyledTextBox required variant="outlined" label="OSAT Description" name="description" placeholder="Add description" value={values.description} onChange={handleInputChange} fullWidth error={!!errors.description} helperText={errors.description} inputProps={{ tabIndex: '2' }} />
          <Grid container>
            <Grid item xs={6}>
              <StyledTextBox required variant="outlined" label="FTP Server (IP or Domain)" name="serverAddress" placeholder="ftp://ftp.dlptest.com/" value={values.serverAddress} onChange={handleInputChange} fullWidth error={!!errors.ftpServer} helperText={errors.ftpServer} inputProps={{ tabIndex: '3' }} />
              <StyledTextBox required variant="outlined" label="FTP Port" name="ftpPort" placeholder="21" value={values.ftpPort} onChange={handleInputChange} fullWidth error={!!errors.ftpPort} helperText={errors.ftpPort} inputProps={{ tabIndex: '5' }} />
              <StyledTextBox required variant="outlined" label="Account" name="accountName" placeholder="dlpuser" value={values.accountName} onChange={handleInputChange} fullWidth error={!!errors.accountName} helperText={errors.accountName} inputProps={{ tabIndex: '7' }} />
              <StyledTextBox required variant="outlined" label="Primary HUB Reserved IP" name="reservedHubIpPrimary" placeholder="0.0.0.0" value={values.reservedHubIpPrimary} onChange={handleInputChange} fullWidth error={!!errors.reservedHubIpPrimary} helperText={errors.reservedHubIpPrimary} inputProps={{ tabIndex: '9' }} />
            </Grid>
            <Grid item xs={6}>
              <StyledFormCtrl variant="outlined" fullWidth>
                <InputLabel id="ftpProtocolLabel" required>FTP Protocol</InputLabel>
                <Select
                  required
                  name="ftpProtocol"
                  value={values.ftpProtocol}
                  label="FTP Protocol"
                  onChange={handleInputChange}
                  inputProps={{ tabIndex: '4' }}
                >
                  <MenuItem value="FTP">FTP</MenuItem>
                  <MenuItem value="SFTP_Password">SFTP (Password)</MenuItem>
                  <MenuItem value="SFTP_PublicKey">SFTP (Public Key)</MenuItem>
                </Select>
              </StyledFormCtrl>
              <StyledTextBox required variant="outlined" label="Root Folder" name="rootFtpDir" placeholder="/" value={values.rootFtpDir} onChange={handleInputChange} fullWidth error={!!errors.rootFolder} helperText={errors.rootFolder} inputProps={{ tabIndex: '6' }} />
              <StyledTextBox required variant="outlined" type="password" label="Password" name="password" placeholder="rNrKYTX9g7z3RgJRmxW" value={values.password} onChange={handleInputChange} fullWidth error={!!errors.password} helperText={errors.password} inputProps={{ tabIndex: '8' }} />
              <StyledTextBox required variant="outlined" label="Backup HUB Reserved IP" name="reservedHubIpBackup" placeholder="0.0.0.0" value={values.reservedHubIpBackup} onChange={handleInputChange} fullWidth error={!!errors.reservedHubIpBackup} helperText={errors.reservedHubIpBackup} inputProps={{ tabIndex: '10' }} />
            </Grid>
          </Grid>
          <div>
            <StyledLoadingButton
              type="submit"
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
            >
              Submit
            </StyledLoadingButton>
            <StyledButton variant="outlined" size="large" text="Cancel" onClick={() => setOpenPopup(false)}> Cancel </StyledButton>
          </div>
        </StyledForm>
      </form>
    </div>
  );
}

FactoryForm.propTypes = {
  setOpenPopup: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  setValues: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  callCreateOsatAPI: PropTypes.func.isRequired,
  callUpdateOsatAPI: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
