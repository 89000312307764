import { NavLink } from 'react-router-dom';
import React from 'react';
import Stack from '@mui/material/Stack';
import './Sidebar.css';

/**
 * Returns Sidebar
 * @return {Component}
 */
function Sidebar() {
  return (
    <div className="navbar" data-testid="SidebarComponent">
      <Stack>
        <NavLink
          to="/requests"
          className="link"
        >
          Requests
        </NavLink>
        <NavLink
          to="/factoryhubs"
          className="link"
        >
          Factory Hubs
        </NavLink>
        <NavLink
          to="/factories"
          className="link"
        >
          Factories
        </NavLink>
      </Stack>
    </div>
  );
}

export default Sidebar;
