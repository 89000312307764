class MSProfile {
  isAuthorizedUser = false;

  isAdminUser = false;

  REACT_APP_AUTHORITY = process.env.REACT_APP_AUTHORITY;

  REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

  REACT_APP_REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

  REACT_APP_SKYHI_BASE_URL = process.env.REACT_APP_SKYHI_BASE_URL;

  REACT_APP_SKYHI_USER_ID = process.env.REACT_APP_SKYHI_USER_ID;

  REACT_APP_SKYHI_ADMIN_ID = process.env.REACT_APP_SKYHI_ADMIN_ID;

  SetEnvVariables(result) {
    if (result) {
      this.REACT_APP_AUTHORITY = result.REACT_APP_AUTHORITY;
      this.REACT_APP_CLIENT_ID = result.REACT_APP_CLIENT_ID;
      this.REACT_APP_REDIRECT_URI = result.REACT_APP_REDIRECT_URI;
      this.REACT_APP_SKYHI_BASE_URL = result.REACT_APP_SKYHI_BASE_URL;
      this.REACT_APP_SKYHI_USER_ID = result.REACT_APP_SKYHI_USER_ID;
      this.REACT_APP_SKYHI_ADMIN_ID = result.REACT_APP_SKYHI_ADMIN_ID;
    }
  }

  SetUserRoles(usersGroups) {
    this.isAdminUser = usersGroups.includes(this.REACT_APP_SKYHI_ADMIN_ID);
    this.isAuthorizedUser = this.isAdminUser || usersGroups.includes(this.REACT_APP_SKYHI_USER_ID);
  }
}

const Profile = new MSProfile();
export default Profile;
