import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import FilesTable from './FilesTable/FilesTable';
import HistoryTable from './HistoryTable';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      <Box style={{ padding: 0 }} p={3}>
        {children}
      </Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  index: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
};

function RequestsBottomPanel(props) {
  const [value, setValue] = useState(1);
  const { selectedSkyIdData } = props;
  // console.log('TESTING IN TBP', selectedSkyIdData);
  const skyID = (selectedSkyIdData?.skyID ? selectedSkyIdData.skyID : '');
  const partNum = (selectedSkyIdData?.partNum ? selectedSkyIdData.partNum : '');
  const opnStepsData = (selectedSkyIdData?.stepsArray ? selectedSkyIdData.stepsArray : '');

  RequestsBottomPanel.propTypes = {
    selectedSkyIdData: PropTypes.exact({
      skyID: PropTypes.string.isRequired,
      partNum: PropTypes.string.isRequired,
      stepsArray: PropTypes.array.isRequired,
    }).isRequired,
  };

  if (skyID) {
    return (
      <div className="AppBar" data-testid="RequestsBottomPanelComponent">
        <nav>
          <AppBar position="static" color="default" style={{ height: '48px', backgroundColor: '#c7c7c7' }}>
            <Toolbar disableGutters style={{ minHeight: '48px' }}>
              <Grid justify="space-between" container>
                <Grid xs={5} item>
                  <p style={{ padding: '16px' }}>
                    Part Num:
                    {' '}
                    {partNum}
                  </p>
                </Grid>
                <Grid item xs={3} />
                <Grid xs={4} item>
                  <Grid container justifyContent="flex-end">
                    <Tabs
                      onChange={(e, v) => setValue(v)}
                      value={value}
                      aria-label="Navigation Tabs"
                    >
                      <Tab label="Files" data-testid="filesTab" />
                      <Tab label="History" data-testid="historyTab" />
                    </Tabs>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </nav>
        <TabPanel value={value} index={0}>
          <FilesTable selectedSkyID={skyID} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <HistoryTable selectedInfoSteps={opnStepsData} />
        </TabPanel>
      </div>
    );
  }
  return (<div style={{ display: 'none' }} />);
}

export default RequestsBottomPanel;
