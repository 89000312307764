import React from 'react';
import { useMsal } from '@azure/msal-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';

const theme = createTheme({
  palette: {
    neutral: {
      main: '#1DA1F2',
      contrastText: '#fff',
    },
  },
});

/**
 * Renders a sign-out button
 * @return {Component}
 */
export default function SignOutButton() {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    if (logoutType === 'popup') {
      instance.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      });
    } else if (logoutType === 'redirect') {
      instance.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
    }
  };

  return (
    <div
      title="Sign Out"
    >
      {/* <button
        type="button"
        as="button"
        onClick={
          () => handleLogout('popup')
                }
      >
        Sign out using Popup
      </button> */}
      <ThemeProvider theme={theme}>
        <Button
          color="neutral"
          variant="contained"
          onClick={
            () => handleLogout('redirect')
            }
        >
          Sign out
        </Button>
      </ThemeProvider>
    </div>
  );
}
