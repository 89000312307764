import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Pagination from '@mui/material/Pagination';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as OpnService from 'modules/common/components/ApiServices/OPNService/OpnApi';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import RequestsBottomPanel from './TableSubPanels/RequestsBottomPanel';
import OPNHeadCells from './OPNRequestsData/OPNRequestsTableConfig';
import { MenuPaperPropsStyle } from '../TableFunctions/StyleFunctions';
import IngestionDialogBox from './OPNRequestDialogs/OPNRequestDialogs';
import './OPNRequestsTable.css';
import Profile from '../../../../Authentication/components/MSProfile';
import '../Shared/TablesStyles.css';
import { TableLoading } from '../Shared/TableLoading';

function GetIngestionStepStatus(steps, findStep) {
  const ingestionSteps = steps.filter(
    (item) => item.opnStepName === findStep && item.opnStepStatus === 'Completed',
  );
  if (ingestionSteps !== null && ingestionSteps.length > 0) {
    return true;
  }
  return false;
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const Dpage = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={Dpage + 1}
      showFirstButton
      showLastButton
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function OPNRequestsTable(props) {
  const {
    getOPNs,
    setData,
    data,
    setTotalRecords,
    totalRecords,
    setLoadingData,
    OpnListApiCallParams,
    loadingData,
  } = props;
  // Pagination State Components
  const [selectedPartNum, setSelectedPartNum] = useState('');
  const [selectedSkyID, setSelectedSkyID] = useState('');
  const [selectedSkyIDStepsData, setSelectedSkyIDStepsData] = useState([]);
  const [runIngestionStepOnSkyID, setRunIngestionStepOnSkyID] = useState('');
  const [canRunIngestionSteps, setCanRunIngestionSteps] = useState(false);
  const [canRunAgilePlmStep, setCanRunAgilePlmStep] = useState(false);
  // const [page, setPage] = useState(0);
  const rowsPerPage = 40;

  // Dialog Modules State Component
  const [openDialogName, setOpenDialog] = React.useState('');

  // Menu State Components
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openedMenu = Boolean(anchorEl);

  const handleIconButtonClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const openIngestionStepDialog = (dialogName) => {
    setOpenDialog(dialogName);
    handleMenuClose();
  };

  const closeDialog = () => {
    setOpenDialog('');
  };

  const [actionAdded, setActionAdded] = useState(false);
  const [apiError, setApiError] = useState('');
  const [apiSuccess, setApiSuccess] = useState('');
  const [showToast, setShowToast] = useState(false);

  const closeToast = () => {
    setShowToast(false);
    setLoadingData(false);
  };
  const runIngestionStep = (stepName) => {
    setLoadingData(true);
    const array = OpnService.startIngestion(runIngestionStepOnSkyID, stepName);
    array.then((response) => {
      if (!response || response.status !== 200) {
        setApiError(response.toString());
        setApiSuccess('');
        setShowToast(true);
      } else {
        let successMsg = 'Successful';
        switch (stepName) {
          case OpnService.IngestionStep.OpnFilesIngested:
            successMsg = `Load Files from HI FE ${successMsg}`;
            break;
          case OpnService.IngestionStep.TestFileGenerated:
            successMsg = `Generate test files ${successMsg}`;
            break;
          case OpnService.IngestionStep.CreatedInSap:
            successMsg = `Create in SAP ${successMsg}`;
            break;
          case OpnService.IngestionStep.CreatedInAplm:
            successMsg = `Create in Agile PLM ${successMsg}`;
            break;
          default:
            successMsg = `Unknown ${successMsg}`;
            break;
        }

        setApiError('');
        setApiSuccess(`${response.status.toString()} ${successMsg}`);
        setShowToast(true);
      }
    });
    setLoadingData(false);
    closeDialog();
  };

  const refresh = () => {
    setLoadingData(true);
    const array = OpnService.getOPNList(
      OpnListApiCallParams.pageIdx,
      OpnListApiCallParams.itemsPerPage,
      OpnListApiCallParams.filterByFields,
      OpnListApiCallParams.sort,
      OpnListApiCallParams.includePartStepLogs,
    );
    array?.then((response) => {
      if (!response.data) {
        setApiError(response.toString());
        setShowToast(true);
      } else {
        setData(response.data.data);
        setTotalRecords(response.data.totalRecords);
        setLoadingData(false);
      }
    });
  };

  useEffect(() => {
    const opns = getOPNs();
    const printArray = async () => {
      opns?.then((response) => {
        if (!response.data) {
          setApiError(response.toString());
          setShowToast(true);
        } else {
          setData(response.data.data);
          setTotalRecords(response.data.totalRecords);
          setLoadingData(false);
        }
      });
    };
    if (loadingData) {
      printArray();
    }
  }, []);

  const rows = data;

  const SkyHiIdData = {
    skyID: selectedSkyID,
    partNum: selectedPartNum,
    stepsArray: selectedSkyIDStepsData,
  };

  // Append to OPNHeadCell objects
  useEffect(() => {
    OPNHeadCells.push({
      field: 'action',
      numeric: false,
      disabledPadding: false,
      headerName: 'Action',
      sortable: false,
      minWidth: 70,
      cellClassName: 'Action',
      disableClickEventBubbling: true,
      renderCell: () => (
        <IconButton
          onClick={handleIconButtonClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={openedMenu ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openedMenu ? 'true' : undefined}
        >
          <MoreVertIcon />
        </IconButton>
      ),
    });
    setActionAdded(true);
  }, []);

  const currentlySelected = (selected, event) => {
    // console.log('row click', selected);
    if (selected.field === 'opnId') {
      const clickEvent = event.target.localName;
      let newPartNumSelected = '';
      let userSelectedSkyId = '';

      if (selected.id !== selectedSkyID) {
        // element is new
        userSelectedSkyId = selected.id;
        newPartNumSelected = selected.row.opnId;
      } else if (selected.id === selectedSkyID) {
        // element already selected so unSelect
        if (clickEvent === 'div') {
          userSelectedSkyId = '';
          newPartNumSelected = '';
        } else {
          userSelectedSkyId = selectedSkyID;
          newPartNumSelected = selectedPartNum;
        }
      }

      setSelectedPartNum(newPartNumSelected);
      setSelectedSkyID(userSelectedSkyId);
      setSelectedSkyIDStepsData(selected.row.steps);
    }

    if (selected.field === 'action') {
      setRunIngestionStepOnSkyID(selected.id);
      setCanRunIngestionSteps(
        GetIngestionStepStatus(selected.row.steps, OpnService.IngestionStep.OpnFilesIngested),
      );
      setCanRunAgilePlmStep(
        GetIngestionStepStatus(selected.row.steps, OpnService.IngestionStep.CreatedInSap),
      );
    }
  };

  return (
    <div className="primary2" data-testid="OPNRequestsTableComponent">
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showToast} onClose={closeToast}>
        {apiError ? <MuiAlert onClose={closeToast} selevation={6} severity="error" variant="filled" sx={{ width: '100%' }}>{apiError}</MuiAlert>
          : <MuiAlert onClose={closeToast} selevation={6} severity="success" variant="filled" sx={{ width: '100%' }}>{apiSuccess}</MuiAlert>}
      </Snackbar>
      {actionAdded ? (
        <div className="primary3">
          <div className="opnTotalResultsInfo">
            <Button onClick={refresh}>
              <RefreshIcon fontSize="medium" />
              Refresh
            </Button>
            <p style={{ paddingTop: '10px' }}>{`Showing ${rowsPerPage} of ${totalRecords} results`}</p>
          </div>
          <IngestionDialogBox
            openDialogName={openDialogName}
            handleClose={closeDialog}
            runIngestionStep={runIngestionStep}
          />
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openedMenu}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={MenuPaperPropsStyle}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={() => openIngestionStepDialog(OpnService.IngestionStep.OpnFilesIngested)}
              disabled={!Profile.isAdminUser}
            >
              <ListItemIcon>
                <AddBoxIcon fontSize="medium" />
              </ListItemIcon>
              Load Files from HI FE
            </MenuItem>
            <MenuItem
              onClick={() => openIngestionStepDialog(OpnService.IngestionStep.TestFileGenerated)}
              disabled={!Profile.isAdminUser || !canRunIngestionSteps}
            >
              <ListItemIcon>
                <AddBoxIcon fontSize="medium" />
              </ListItemIcon>
              Generate test files
            </MenuItem>
            <MenuItem
              onClick={() => openIngestionStepDialog(OpnService.IngestionStep.CreatedInSap)}
              disabled={!Profile.isAdminUser || !canRunIngestionSteps}
            >
              <ListItemIcon>
                <AddBoxIcon fontSize="medium" />
              </ListItemIcon>
              Create in SAP
            </MenuItem>
            <MenuItem
              onClick={() => openIngestionStepDialog(OpnService.IngestionStep.CreatedInAplm)}
              disabled={!Profile.isAdminUser || !canRunIngestionSteps || !canRunAgilePlmStep}
            >
              <ListItemIcon>
                <AddBoxIcon fontSize="medium" />
              </ListItemIcon>
              Create in Agile PLM
            </MenuItem>
          </Menu>
          <Box sx={{ width: '100%', heigth: '100%', display: 'flex' }} id="boxContainer">
            <Paper
              sx={
              {
                width: '100%', mb: 2, border: 1, borderColor: 'rgb(224, 221, 221)', height: selectedSkyID ? '300px' : '100%', minHeight: '0',
              }
            }
            >
              <DataGrid
                sx={{
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'rgb(229, 229, 229);',
                  },
                  '& .MuiDataGrid-row:nth-of-type(even)': {
                    backgroundColor: '#F9F9F9',
                  },
                  '& .MuiDataGrid-row.Mui-selected': {
                    backgroundColor: 'rgb(207, 232, 247);',
                  },
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: 'rgb(239, 239, 239);',
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                }}
                columns={OPNHeadCells}
                rows={rows}
                getRowId={(row) => row.skyhiGuid}
                key={(row) => row.skyhiGuid}
                onCellClick={currentlySelected}
                pageSize={rowsPerPage}
                rowsPerPage={[rowsPerPage]}
                rowsPerPageOptions={[rowsPerPage]}
                components={{
                  Pagination: CustomPagination,
                  LoadingOverlay: TableLoading,
                }}
                loading={loadingData}
              />
            </Paper>
          </Box>
          <div className="bottomPanel" style={{ marginTop: '24px' }}>
            <RequestsBottomPanel selectedSkyIdData={SkyHiIdData} />
          </div>
        </div>
      ) : null}
    </div>
  );
}

OPNRequestsTable.propTypes = {
  getOPNs: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  setTotalRecords: PropTypes.func.isRequired,
  totalRecords: PropTypes.number.isRequired,
  OpnListApiCallParams: PropTypes.instanceOf(Object).isRequired,
  setLoadingData: PropTypes.func.isRequired,
  loadingData: PropTypes.bool.isRequired,
};
