import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DownloadIcon from '@mui/icons-material/Download';
import * as OpnService from 'modules/common/components/ApiServices/OPNService/OpnApi';
import { StyledTableCell, StyledTableRow } from './FilesTableStyles';

function FilesTable({ selectedSkyID }) {
  FilesTable.propTypes = {
    selectedSkyID: PropTypes.string.isRequired,
  };

  const [selectedFile, setSelectedFile] = useState('');
  const isSelected = (file) => selectedFile === file;
  const [data, setData] = useState([]);
  const [loadingFilesDetails, setLoadingFilesDetails] = useState(false);
  const [filesDetailsData, setFilesDetailsData] = useState('');
  const [apiError, setApiError] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [fileCellContent, setFileCellContent] = useState(null);
  const closeToast = () => {
    setShowToast(false);
  };

  const dataOrSpinner = () => {
    if (filesDetailsData && selectedFile) {
      setLoadingFilesDetails(false);
      setFileCellContent(<TableCell align="left" style={{ height: '150px' }}><pre>{filesDetailsData}</pre></TableCell>);
    }
    if (loadingFilesDetails) {
      setFileCellContent(<TableCell align="left" style={{ height: '150px' }}><Box className="loadingFileContent" sx={{ display: 'flex' }}><CircularProgress /></Box></TableCell>);
    }
    if (!(filesDetailsData && selectedFile) && !loadingFilesDetails) {
      setFileCellContent(null);
    }
  };
  const emptyTableMessage = 'Files will need to be manually extracted '
  + 'from the repository of files that are sent to the OSATs for OPNs '
  + 'that were generated prior to the SkyHI go live date';

  // Getting Files List from API CALL
  useEffect(() => {
    const array = OpnService.getOPNFiles(selectedSkyID);
    const uuid = selectedSkyID.toString().replaceAll('-', '');
    if (uuid.length === 37) {
      array?.then((response) => {
        if (!response.data) {
          setApiError(response.toString());
          setShowToast(true);
        } else {
          setData(response.data);
        }
      });
    } else {
      setData([]);
    }
    setSelectedFile('');
  }, [selectedSkyID]);

  // Handles Files Row Click
  const handleClick = (event, file) => {
    const targetEvent = event.target.localName;
    // if download icon is not clicked it is safe to unselect row
    if (selectedFile === file && targetEvent !== 'path') {
      setSelectedFile('');
    } else if (file.length > 0) {
      setSelectedFile(file);
    }

    if (file !== '') {
      // API CALL for Selected File's URL
      setLoadingFilesDetails(true);
      dataOrSpinner();
      const fileURL = OpnService.getOPNFileURL(selectedSkyID, file);
      fileURL.then((response) => {
        if (!response.data) {
          setApiError(response.toString());
          setShowToast(true);
        } else {
          // API CALL for Selected File's Content
          setLoadingFilesDetails(false);
          const fileContent = OpnService.getOPNFileContent(response.data);
          fileContent.then((res) => {
            if (!res.data) {
              setApiError(res.toString());
              setShowToast(true);
            } else {
              setFilesDetailsData(res.data);
            }
          });
        }
        setLoadingFilesDetails(false);
      });
    }
  };

  // Handles Download Icon Click
  const onClickDownload = (event, file) => {
    const fileURL = OpnService.getOPNFileURL(selectedSkyID, file);
    fileURL.then((response) => {
      if (!response.data) {
        setApiError(response.toString());
        setShowToast(true);
      } else {
        // API CALL for Selected File's Content
        const fileContent = OpnService.getOPNFileContent(response.data);
        fileContent.then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file);
          document.body.appendChild(link);
          link.click();
        });
      }
    });
  };

  return (
    <div className="FilesTable" data-testid="FilesTableComponent">
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showToast} onClose={closeToast}>
        <MuiAlert onClose={closeToast} selevation={6} severity="error" variant="filled" sx={{ width: '100%' }}>{apiError}</MuiAlert>
      </Snackbar>

      <Grid container>
        <Grid item xs={4}>
          <TableContainer
            component={Paper}
            sx={{
              height: 300,
              margin: 'auto',
            }}
          >
            <Table sx={{ minWidth: 400 }} aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Files</StyledTableCell>
                  <StyledTableCell align="center">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <StyledTableRow
                    hover
                    key={row.fileName}
                    onClick={(event) => handleClick(event, row.fileName)}
                    selected={isSelected(row.fileName)}
                  >
                    <TableCell component="th" scope="row" align="left">
                      {row.fileName}
                    </TableCell>
                    <TableCell align="center" width="10%">
                      <DownloadIcon color="primary" onClick={(event) => onClickDownload(event, row.fileName)} />
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={8}>
          { data.length > 0
            ? (
              <TableContainer
                component={Paper}
                sx={{
                  height: 300,
                  margin: 'auto',
                }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">
                        File:
                        {' '}
                        {selectedFile}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow
                      hover
                      key={1}
                      selected
                    >
                      {fileCellContent}
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : <div style={{ display: 'flex', alignItems: 'center', padding: '100px' }}><strong>{emptyTableMessage}</strong></div>}
        </Grid>
      </Grid>
    </div>
  );
}

export default FilesTable;
