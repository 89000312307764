import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E5E5E5',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F9F9F9',
    color: theme.palette.common.black,
  },
  '&.Mui-selected': {
    backgroundColor: 'rgb(207, 232, 247)',
  },
}));

export function EnhancedTableToolbar(props) {
  const { itemSelection } = props;
  const { numSelected } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      Part Number:
      {' '}
      {numSelected}
      {' '}
      {itemSelection}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  itemSelection: PropTypes.string.isRequired,
};
