import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import UpdateIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Pagination from '@mui/material/Pagination';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as OsatService from 'modules/common/components/ApiServices/OSATService/OsatApi';
import OSATHeadCells from './FactoriesRequestsData/FactoriesRequestsTableConfig';
import { MenuPaperPropsStyle } from '../TableFunctions/StyleFunctions';
import './FactoriesTable.css';
import '../Shared/TablesStyles.css';
import { TableLoading } from '../Shared/TableLoading';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      showFirstButton
      showLastButton
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function ServerSideRenderTable(props) {
  const {
    setOpenPopup,
    setIsUpdate,
    setValues,
    setTitle,
    setSelectedOsatID,
    selectedOsatID,
    values,
    setShowToast,
    showToast,
    setApiError,
    apiError,
    apiSuccess,
    setLoadingData,
    loadingData,
  } = props;

  // Menu State Components
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openedMenu = Boolean(anchorEl);
  const handleIconButtonClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  // OSAT API Call Default Variables
  // Per Bug Bug 1320: includeDisabled should be false so
  // the UI isn't populated with old/test OSATs.
  const OsatListApiCallParams = {
    includeDisabled: false,
  };
  // API State Components
  const [data, setData] = useState([]);
  const [responsesTotalRows, setResponsesTotalRows] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);

  useEffect(() => {
    const array = OsatService.getOSATList(
      OsatListApiCallParams.includeDisabled,
    );
    const printArray = async () => {
      await array?.then((response) => {
        if (!response.data) {
          setApiError(response.toString());
          setShowToast(true);
        } else {
          setData(response.data);
          setItemsPerPage(response.data.length);
          setResponsesTotalRows(response.data.length);
          setLoadingData(false);
        }
      });
    };
    if (loadingData) {
      printArray();
    }
  }, []);

  function refreshRequest() {
    const array = OsatService.getOSATList(
      OsatListApiCallParams.includeDisabled,
    );
    array.then((response) => {
      if (!response.data) {
        setApiError(response.toString());
        setShowToast(true);
      } else {
        setData(response.data);
        setResponsesTotalRows(response.data.length);
        setLoadingData(false);
      }
    });
  }

  const closeToast = () => {
    setShowToast(false);
    refreshRequest();
  };

  function setOsatPassword(value) {
    setValues({
      ...values,
      password: value,
    });
  }

  const getOsatPasswordAPI = (osatId) => {
    const array = OsatService.getOSATPassword(osatId);
    array.then((response) => {
      if (!response || response.status !== 200) {
        // console.log('see Password error: ', response.toString());
      } else {
        setOsatPassword(response.data);
      }
    });
  };
  // Popup function
  function beginUpdate(osatID) {
    setIsUpdate(true);
    getOsatPasswordAPI(osatID);
    setOpenPopup(true);
    setTitle('Update Factory');
  }

  const rows = data;

  // Append to OPNHeadCell objects
  useEffect(() => {
    OSATHeadCells.push({
      field: 'action',
      numeric: false,
      disabledPadding: false,
      headerName: 'Action',
      sortable: false,
      minWidth: 70,
      cellClassName: 'Action',
      disableClickEventBubbling: true,
      renderCell: () => (
        <IconButton
          onClick={handleIconButtonClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={openedMenu ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openedMenu ? 'true' : undefined}
        >
          <MoreVertIcon />
        </IconButton>
      ),
    });
  }, []);

  const currentlyOsatSelected = (selected) => {
    const { row } = selected;
    row.password = '';
    setValues(row);
    setSelectedOsatID(row.id);
  };

  return (
    <div>
      <div className="primary2" data-testid="OSATRequestsTableComponent">
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showToast} onClose={closeToast}>
          {apiError ? <MuiAlert onClose={closeToast} selevation={6} severity="error" variant="filled" sx={{ width: '100%' }}>{apiError}</MuiAlert>
            : <MuiAlert onClose={closeToast} selevation={6} severity="success" variant="filled" sx={{ width: '100%' }}>{apiSuccess}</MuiAlert>}
        </Snackbar>
        {loadingData ? (
          <div className="loadingOSAT">
            <p>Loading...</p>
          </div>
        ) : (
          <div className="primary3">
            <div className="osatTotalResultsInfo">
              <p>{`Showing ${responsesTotalRows} results`}</p>
            </div>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openedMenu}
              onClose={handleMenuClose}
              onClick={handleMenuClose}
              PaperProps={MenuPaperPropsStyle}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={() => beginUpdate(selectedOsatID)}>
                <ListItemIcon>
                  <UpdateIcon fontSize="medium" />
                </ListItemIcon>
                Update Factory
              </MenuItem>
            </Menu>
            <Box sx={{ width: '100%' }}>
              <Paper
                sx={
                  {
                    width: '100%', mb: 2, border: 1, borderColor: 'rgb(224, 221, 221)', height: '630px',
                  }
                }
              >
                <DataGrid
                  sx={{
                    '& .MuiDataGrid-columnHeaders': {
                      backgroundColor: 'rgb(229, 229, 229);',
                    },
                    '& .MuiDataGrid-row:nth-of-type(even)': {
                      backgroundColor: '#F9F9F9',
                    },
                    '& .MuiDataGrid-row.Mui-selected': {
                      backgroundColor: 'rgb(207, 232, 247);',
                    },
                    '& .MuiDataGrid-row:hover': {
                      backgroundColor: 'rgb(239, 239, 239);',
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                      outline: 'none',
                    },
                  }}
                  columns={OSATHeadCells}
                  rows={rows}
                  getRowId={(row) => row.id}
                  key={(row) => row.id}
                  onCellClick={currentlyOsatSelected}
                  pageSize={itemsPerPage}
                  rowsPerPage={[itemsPerPage]}
                  rowsPerPageOptions={[itemsPerPage]}
                  paginationMode="server"
                  onPageChange={(Nextpage) => {
                    setLoadingData(true);
                    refreshRequest(Nextpage + 1);
                  }}
                  onPageSizeChange={(pageSize) => setItemsPerPage(pageSize)}
                  rowCount={responsesTotalRows}
                  components={{
                    Pagination: CustomPagination,
                    LoadingOverlay: TableLoading,
                  }}
                  loading={loadingData}
                />
              </Paper>
            </Box>
          </div>
        )}
      </div>
    </div>
  );
}

ServerSideRenderTable.propTypes = {
  setOpenPopup: PropTypes.func.isRequired,
  setIsUpdate: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  setSelectedOsatID: PropTypes.func.isRequired,
  selectedOsatID: PropTypes.string.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  setShowToast: PropTypes.func.isRequired,
  showToast: PropTypes.bool.isRequired,
  setApiError: PropTypes.func.isRequired,
  setLoadingData: PropTypes.func.isRequired,
  loadingData: PropTypes.bool.isRequired,
  apiError: PropTypes.bool.isRequired,
  apiSuccess: PropTypes.bool.isRequired,
};
