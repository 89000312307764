import React, { useState, useEffect } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';

import SyncIcon from '@mui/icons-material/Sync';
import RefreshIcon from '@mui/icons-material/Refresh';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FactoryHubsTable from 'modules/common/components/CustomTables/FactoryHubsTable/FactoryHubsTable';
import { ResyncHubsModal } from 'modules/common/components/CustomTables/FactoryHubsTable/FactoryHubsDialogs/FactoryHubsDialogs';
import { MenuPaperPropsStyle } from 'modules/common/components/CustomTables/TableFunctions/StyleFunctions';
import * as ArtifactService from 'modules/common/components/ApiServices/ArtifactRepoService/ArtifactReposApis';
import * as HubService from 'modules/common/components/ApiServices/FactoryHubsService/FactoryHubsApi';
import * as OsatService from 'modules/common/components/ApiServices/OSATService/OsatApi';
import './FactoryHubs.css';
import Profile from '../../../Authentication/components/MSProfile';

/**
 * Returns FactoryHub Content
 * @return {Component}
 */
function FactoryHubs() {
  // Dialog Modules State Component
  const [openDialogName, setOpenDialog] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openedMenu = Boolean(anchorEl);
  // State Variables
  const [currentCommit, setCurrentCommit] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [apiError, setApiError] = useState('');
  const [apiSuccess, setApiSuccess] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [isLoadCommmit, setLoadCommmit] = useState(false);

  const openHubMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const closeToast = () => {
    setShowToast(false);
  };

  useEffect(() => {
    const array = ArtifactService.getCurrentGitCommit();
    const printArray = async () => {
      await array?.then((response) => {
        if (!response.data) {
          setApiError(`Unable to retrieve 'Current Commit ID' ${response.toString()}`);
          setShowToast(true);
        } else {
          setCurrentCommit(response.data.commitId);
          // console.log('see data', data);
        }
        setLoadingData(false);
        setLoadCommmit(true);
      });
    };
    if (loadingData) {
      printArray();
    }
  }, []);

  const openSyncHubDialog = () => {
    const array = HubService.syncAllHubs();
    array.then((response) => {
      if (!response || response.status !== 200) {
        setApiError(response.toString());
        setApiSuccess('');
        setShowToast(true);
      } else {
        // console.log('see data', response);
        setApiError('');
        setApiSuccess(`${response.status.toString()} Sync All Hubs Success`);
        setShowToast(true);
      }
    });
    handleMenuClose();
  };

  const openRefreshHubDialog = async () => {
    try {
      await OsatService.scanOsatsForUpdates();
      setApiError('');
      setApiSuccess('Started the process to scan all OSATs. Refresh the page after a few minutes.');
      setShowToast(true);
    } catch (error) {
      setApiError(`${error.toString()}`);
      setApiSuccess('');
      setShowToast(true);
    }
    handleMenuClose();
  };

  return (
    <div className="factoryhub" data-testid="FactoryHubComponent">
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showToast} onClose={closeToast}>
        {apiError ? <MuiAlert onClose={closeToast} selevation={6} severity="error" variant="filled" sx={{ width: '100%' }}>{apiError}</MuiAlert>
          : <MuiAlert onClose={closeToast} selevation={6} severity="success" variant="filled" sx={{ width: '100%' }}>{apiSuccess}</MuiAlert>}
      </Snackbar>
      <ResyncHubsModal openResyncModal={openDialogName === 'ResyncHub'} handleCloseResyncModal={closeDialog} />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openedMenu}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={MenuPaperPropsStyle}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={openSyncHubDialog} disabled={!Profile.isAdminUser}>
          <ListItemIcon>
            <SyncIcon fontSize="small" />
          </ListItemIcon>
          Resync All Hubs
        </MenuItem>
        <MenuItem onClick={openRefreshHubDialog} disabled={!Profile.isAdminUser}>
          <ListItemIcon>
            <RefreshIcon fontSize="small" />
          </ListItemIcon>
          Refresh Hubs Status
        </MenuItem>
      </Menu>

      <div className="flexContainer">
        <div className="flexItem1">
          <span className="title">Factory Hubs</span>
        </div>
        <div className="flexItem2">
          <div className="currentCommit">Current Commit ID:</div>
          <div className="commitID">{currentCommit}</div>
        </div>
        <div className="flexItem3">
          <IconButton onClick={openHubMenu}>
            <MoreVertIcon className="moreIcon" />
          </IconButton>
        </div>
      </div>
      { isLoadCommmit ? (
        <FactoryHubsTable commitId={currentCommit} />
      ) : null }
    </div>
  );
}

export default FactoryHubs;
