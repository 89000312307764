import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Pagination from '@mui/material/Pagination';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import SyncIcon from '@mui/icons-material/Sync';
import ArchiveIcon from '@mui/icons-material/Archive';
import UpdateIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import * as HubService from 'modules/common/components/ApiServices/FactoryHubsService/FactoryHubsApi';
import FactoryHubsHeadCells from './FactoryHubsSampleData/FactoryHubsSampleData';
import { MenuPaperPropsStyle } from '../TableFunctions/StyleFunctions';
import Profile from '../../../../Authentication/components/MSProfile';
import {
  DisableHubDialog, ModalSample, SyncHubDialog, UpdateHubDialog, DeleteHubDialog,
} from './FactoryHubsDialogs/FactoryHubsDialogs';
import { TableLoading } from '../Shared/TableLoading';
import '../Shared/TablesStyles.css';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const Dpage = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={Dpage + 1}
      showFirstButton
      showLastButton
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function FactoryHubsTable({ commitId }) {
  const currentCommit = commitId;
  const columns = FactoryHubsHeadCells;
  const [selectedId, setSelected] = useState('');
  // API State Components
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [osatId, setOsatId] = useState('');
  const [description, setDescription] = useState('');
  const [apiError, setApiError] = useState('');
  const [apiSuccess, setApiSuccess] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [actionAdded, setActionAdded] = useState(false);
  const showDeletedHubs = false;
  const rows = data;

  const rowsPerPage = 40;

  // Dialog Modules State Component
  const [openDialogName, setOpenDialog] = useState(null);

  // Menu State Components
  const [anchorEl, setAnchorEl] = useState(null);
  const openedMenu = Boolean(anchorEl);

  const handleIconButtonClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const openSyncHubDialog = () => {
    setOpenDialog('SyncHub');
    handleMenuClose();
  };

  const closeToast = () => {
    setShowToast(false);
    setLoadingData(false);
  };

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const openDisableHubDialog = () => {
    setOpenDialog('DisableHub');
    handleMenuClose();
  };

  const openUpdateHubDialog = () => {
    setOpenDialog('UpdateHub');
    handleMenuClose();
  };

  const openDeleteHubDialog = () => {
    setOpenDialog('DeleteHub');
    handleMenuClose();
  };

  // const openTestModule = () => {
  //   setOpenDialog('TestModule');
  //   handleMenuClose();
  // };

  const handleClick = (selected, event) => {
    const clickEventTarget = event.target.localName;
    let newSelectedHubId = '';
    let newselectedOsatId = '';
    let newselectedDescription = '';

    if (selected.field === 'action' && (clickEventTarget === 'BUTTON' || clickEventTarget === 'svg' || clickEventTarget === 'path')) {
      newSelectedHubId = selected.id;
      newselectedOsatId = selected.row.osat.id;
      newselectedDescription = selected.row.description;
    }

    setSelected(newSelectedHubId);
    setOsatId(newselectedOsatId);
    setDescription(newselectedDescription);
  };

  useEffect(() => {
    setLoadingData(true);
    const array = HubService.getFactoryHubs(showDeletedHubs);
    const printArray = async () => {
      await array?.then((response) => {
        if (!response.data) {
          setApiError(response.toString());
          setApiSuccess('');
        } else {
          setApiError('');
          setApiSuccess('');
          setData(response.data.map((rowData) => {
            const row = rowData;
            row.currentCommit = currentCommit;
            return row;
          }));
          setLoadingData(false);
        }
      });
      setLoadingData(false);
    };
    if (loadingData) {
      printArray();
    }
  }, []);

  // Append to FactoryHubsHeadCells objects
  useEffect(() => {
    FactoryHubsHeadCells.push({
      field: 'action',
      numeric: false,
      disabledPadding: false,
      headerName: 'Action',
      sortable: false,
      minWidth: 70,
      cellClassName: 'Action',
      disableClickEventBubbling: true,
      renderCell: () => (
        <IconButton
          onClick={handleIconButtonClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={openedMenu ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openedMenu ? 'true' : undefined}
        >
          <MoreVertIcon />
        </IconButton>
      ),
    });
    setActionAdded(true);
  }, []);

  const refreshHubs = () => {
    setLoadingData(true);
    const array = HubService.getFactoryHubs(showDeletedHubs);
    array?.then((response) => {
      if (!response.data) {
        setApiError(response.toString());
        setShowToast(true);
      } else {
        setData(response.data.map((rowData) => {
          const row = rowData;
          row.currentCommit = currentCommit;
          return row;
        }));
        setLoadingData(false);
      }
      setLoadingData(false);
    });
  };

  const callDisableHubAPI = () => {
    setLoadingData(true);
    const array = HubService.updateFactoryHub({
      hubId: selectedId,
      isDeleted: true,
      description: `Disabling hubId: ${selectedId}`,
    });
    array.then((response) => {
      if (!response || response.status !== 200) {
        setApiError(response.toString());
        setApiSuccess('');
        setShowToast(true);
      } else {
        setApiError('');
        setApiSuccess(`${response.status.toString()} Delete Hub Success`);
        setShowToast(true);
        refreshHubs();
      }
      setLoadingData(false);
    });
    closeDialog();
  };

  const callSyncHubAPI = () => {
    setLoadingData(true);
    const array = HubService.syncHub(selectedId, osatId);
    array.then((response) => {
      if (!response || response.status !== 200) {
        setApiError(response.toString());
        setApiSuccess('');
        setShowToast(true);
      } else {
        setApiError('');
        setApiSuccess(`${response.status.toString()} Sync Hub Success`);
        setShowToast(true);
        refreshHubs();
      }
      setLoadingData(false);
    });
    closeDialog();
  };

  const callUpdateHubAPI = (newDescription) => {
    setLoadingData(true);
    const array = HubService.updateFactoryHub({
      hubId: selectedId,
      isDeleted: false,
      description: newDescription,
    });
    array.then((response) => {
      if (!response || response.status !== 200) {
        setApiError(response.toString());
        setApiSuccess('');
        setShowToast(true);
      } else {
        setApiError('');
        setApiSuccess(`${response.status.toString()} Update Hub Success`);
        setShowToast(true);
        refreshHubs();
        closeDialog();
      }
      setLoadingData(false);
    });
  };

  const callDeleteHubAPI = () => {
    setLoadingData(true);
    const array = HubService.updateFactoryHub({
      hubId: selectedId,
      isDeleted: true,
      description: undefined,
    });
    array.then((response) => {
      if (!response || response.status !== 200) {
        setApiError(response.toString());
        setApiSuccess('');
        setShowToast(true);
      } else {
        setApiError('');
        setApiSuccess(`${response.status.toString()} Delete Hub Success`);
        setShowToast(true);
        refreshHubs();
        closeDialog();
      }
      setLoadingData(false);
    });
  };

  return (
    <div className="factoryHubsMainCompoent" data-testid="FactoryHubsTableComponent">
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showToast} onClose={closeToast}>
        {apiError ? <MuiAlert onClose={closeToast} selevation={6} severity="error" variant="filled" sx={{ width: '100%' }}>{apiError}</MuiAlert>
          : <MuiAlert onClose={closeToast} selevation={6} severity="success" variant="filled" sx={{ width: '100%' }}>{apiSuccess}</MuiAlert>}
      </Snackbar>
      {actionAdded ? (
        <div className="factoryHubsTableContent">
          <SyncHubDialog openSyncHub={openDialogName === 'SyncHub'} callSyncHubAPI={callSyncHubAPI} handleCloseSyncHub={closeDialog} />
          <ModalSample openModal={openDialogName === 'TestModule'} handleCloseModal={closeDialog} />
          <DisableHubDialog openDisableHub={openDialogName === 'DisableHub'} callDisableHubAPI={callDisableHubAPI} handleCloseDisableHub={closeDialog} />
          <UpdateHubDialog openUpdateHub={openDialogName === 'UpdateHub'} callUpdateHubAPI={callUpdateHubAPI} handleCloseUpdateHub={closeDialog} rowDescription={description} />
          <DeleteHubDialog openDeleteHub={openDialogName === 'DeleteHub'} callDeleteHubAPI={callDeleteHubAPI} handleCloseDeleteHub={closeDialog} />

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openedMenu}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={MenuPaperPropsStyle}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={openSyncHubDialog} disabled={!Profile.isAdminUser}>
              <ListItemIcon>
                <SyncIcon fontSize="small" />
              </ListItemIcon>
              SyncHub
            </MenuItem>
            { /* <MenuItem onClick={openTestModule}>
              <ListItemIcon>
               <Settings fontSize="small" />
              </ListItemIcon>
              Set as Primary Hub
            </MenuItem> */ }
            <MenuItem onClick={openDisableHubDialog} disabled={!Profile.isAdminUser}>
              <ListItemIcon>
                <ArchiveIcon fontSize="small" />
              </ListItemIcon>
              Disable/Archive Hub
            </MenuItem>
            <MenuItem onClick={openUpdateHubDialog} disabled={!Profile.isAdminUser}>
              <ListItemIcon>
                <UpdateIcon fontSize="small" />
              </ListItemIcon>
              Update
            </MenuItem>
            <MenuItem onClick={openDeleteHubDialog} disabled={!Profile.isAdminUser}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              Delete
            </MenuItem>
          </Menu>
          <Box sx={{ width: '100%' }}>
            <Paper
              sx={
                 {
                   width: '100%', mb: 2, border: 1, borderColor: 'rgb(224, 221, 221)', height: selectedId ? '300px' : '650px',
                 }
              }
            >
              <DataGrid
                sx={{
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'rgb(229, 229, 229);',
                  },
                  '& .MuiDataGrid-row:nth-of-type(even)': {
                    backgroundColor: '#F9F9F9',
                  },
                  '& .MuiDataGrid-row.Mui-selected': {
                    backgroundColor: 'rgb(207, 232, 247);',
                  },
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: 'rgb(239, 239, 239);',
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                }}
                rows={rows}
                columns={columns}
                columnBuffer={columns.length}
                getRowId={(row) => row.id}
                key={(row) => row.id}
                onCellClick={handleClick}
                pageSize={rowsPerPage}
                rowsPerPage={[rowsPerPage]}
                rowsPerPageOptions={[rowsPerPage]}
                components={{
                  Pagination: CustomPagination,
                  LoadingOverlay: TableLoading,
                }}
                loading={loadingData}
              />
            </Paper>
          </Box>
        </div>
      ) : null}
    </div>
  );
}

FactoryHubsTable.propTypes = {
  commitId: PropTypes.string.isRequired,
};
