import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import PropTypes from 'prop-types';

export default function FactoryModal(props) {
  const {
    title,
    children,
    openPopup,
    setOpenPopup,
  } = props;

  return (
    <Dialog open={openPopup}>
      <DialogTitle>
        <div style={{ display: 'flex' }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <Button variant="contained" color="secondary" onClick={() => setOpenPopup(false)}> X </Button>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {children}
      </DialogContent>
    </Dialog>
  );
}

FactoryModal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  openPopup: PropTypes.bool.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
};
