import React, { useState } from 'react';
import dayjs from 'dayjs';
import OPNRequestsTable from 'modules/common/components/CustomTables/OPNRequestsTable/OPNRequestsTable';
import SearchForm from 'modules/common/components/CustomForms/OpnForm/SearchForm';
import './Requests.css';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Profile from 'modules/Authentication/components/MSProfile';
import Cookies from 'universal-cookie';
import * as OpnService from '../../../common/components/ApiServices/OPNService/OpnApi';
/**
 * Returns Request Content for Main Panel
 * @return {data}
 */
function Requests() {
  const [apiError, setApiError] = useState('');
  const [apiSuccess, setApiSuccess] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const cookies = new Cookies();
  // Search Values
  const iniSearchValues = {
    opnId: cookies.get('opnId') ?? '',
    partFamilyName: cookies.get('partFamilyName') ?? '',
    basePartName: cookies.get('basePartName') ?? '',
    dateFrom: cookies.get('createdFrom') === undefined ? dayjs().subtract(30, 'day').toJSON() : cookies.get('createdFrom'),
    dateTo: cookies.get('createdTo') === undefined ? dayjs().toJSON() : cookies.get('createdTo'),
  };
  const [searchValues, setSearchValues] = useState(iniSearchValues);

  // OPN API Call Default Variables
  const OpnListApiCallParams = {
    pageIdx: 0,
    itemsPerPage: 0,
    includePartStepLogs: true,
    filterByFields: JSON.stringify(searchValues),
    sort: '',
  };

  const closeToast = () => {
    setShowToast(false);
  };
  const triggerOrchestration = async (e) => {
    e.preventDefault();
    try {
      const response = await OpnService.opnOrchestration();
      setApiError('');
      setApiSuccess(`${response.data.found}  OPNs found`);
      setShowToast(true);
    } catch (error) {
      setApiError(error.toString());
      setApiSuccess('');
      setShowToast(true);
    }
  };

  // Get the opns
  const getOPNs = async () => {
    setLoadingData(true);
    const array = OpnService.getOPNList(
      OpnListApiCallParams.pageIdx,
      OpnListApiCallParams.itemsPerPage,
      OpnListApiCallParams.filterByFields,
      OpnListApiCallParams.sort,
      OpnListApiCallParams.includePartStepLogs,
    );
    return array;
  };

  return (
    <div className="requests" data-testid="requestsComponent">
      {/* refactor toast to be a common component. */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showToast} onClose={closeToast}>
        {apiError ? <MuiAlert onClose={closeToast} selevation={6} severity="error" variant="filled" sx={{ width: '100%' }}>{apiError}</MuiAlert>
          : <MuiAlert onClose={closeToast} selevation={6} severity="success" variant="filled" sx={{ width: '100%' }}>{apiSuccess}</MuiAlert>}
      </Snackbar>
      <div className="requestTitleBar">
        <span className="title" data-testid="header">Requests</span>
        <Button variant="text" size="small" onClick={triggerOrchestration} disabled={!Profile.isAdminUser}>Check HI FE for updates</Button>
      </div>
      <div>
        <SearchForm
          setSearchValues={setSearchValues}
          iniSearchValues={iniSearchValues}
          searchValues={searchValues}
          getOPNs={getOPNs}
          OpnListApiCallParams={OpnListApiCallParams}
          setData={setData}
          loadingData={loadingData}
          setLoadingData={setLoadingData}
          setApiError={setApiError}
          setApiSuccess={setApiSuccess}
          setShowToast={setShowToast}
          setTotalRecords={setTotalRecords}
        />
      </div>
      <OPNRequestsTable
        searchValues={searchValues}
        getOPNs={getOPNs}
        setData={setData}
        data={data}
        setTotalRecords={setTotalRecords}
        totalRecords={totalRecords}
        OpnListApiCallParams={OpnListApiCallParams}
        loadingData={loadingData}
        setLoadingData={setLoadingData}
      />
    </div>
  );
}

export default Requests;
