import React from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import logo from 'assets/SkyWorksLogo.png';
import SignOutButton from 'modules/Authentication/components/SignOutButton';
import './UnauthorizedPage.css';

function UnauthorizedPage() {
  return (
    <div className="unAuthorizedPage" data-testid="UnAuthPageComponent">
      <div className="unauthorized-message">

        <div className="skyHi-logo">
          <img alt="" src={logo} width="100" />
        </div>

        <div className="lock-icon">
          <LockOutlinedIcon color="primary" />
        </div>

        <div className="unAuth-msg">
          Sorry, Inquire with Administrators access SkyHi.
        </div>

      </div>
      <div className="SignOut-button">
        <SignOutButton />
      </div>
    </div>
  );
}

export default UnauthorizedPage;
