import React from 'react';
import { CircularProgress } from '@mui/material';

export function TableLoading() {
  return (
    <div style={
        {
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
        }
    }
    >
      <CircularProgress />
    </div>
  );
}
