import { getAPIRequest, getFileURLContent, postAPIRequest } from '../AxiosApis';

const baseRoute = 'opn';

export const IngestionStep = {
  OpnFilesIngested: 'OpnFilesIngested',
  TestFileGenerated: 'TestFileGenerated',
  CreatedInSap: 'CreatedInSap',
  CreatedInAplm: 'CreatedInAplm',
};

/**
* @description OPN List API Call
* @param {boolean} inlcudesPartStepLogs required,
if TRUE we get back the OPN Request and Part Steps Logs
* @param {integer} pageIdx which page you want returned
* @param {integer} itemsPerPage how many items per page returned
* @returns {object} API Response with OPNs
*/
export async function getOPNList(pageIdx, itemsPerPage, search, sort, includePartStepLogs) {
  const params = {
    pageIdx,
    itemsPerPage,
    search,
    sort,
    includePartStepLogs,
  };
  try {
    return await getAPIRequest(`${baseRoute}/opns`, params);
  } catch (error) {
    return error;
  }
}

/**
* @description OPN Retry Ingestion step.
* @param {string} skyHiId required, id of OPN request
* @param {string} step required, OPN step to start
* @returns {object} API Response returns Status Code
*/
export async function startIngestion(skyHiId, step) {
  const params = { step };
  try {
    return await postAPIRequest(`${baseRoute}/startingestion/${skyHiId}`, {}, params);
  } catch (error) {
    return error;
  }
}

/**
* @description OPN Files List API Call
* @param {string} skyHiId pertaining to the files requested
* @returns {object} API Response with OPN Files
*/
export async function getOPNFiles(skyHiId) {
  try {
    return await getAPIRequest(`${baseRoute}/${skyHiId}/files`, {});
  } catch (error) {
    return error;
  }
}

/**
* @description OPN File URL API Call
* @param {string} skyHiId SkyHiID related to the files requested
* @param {string} fileName File Name of file pertaining to the details requested
* @returns {object} API Response with OPN Files
*/
export async function getOPNFileURL(skyHiId, fileName) {
  try {
    return await getAPIRequest(`${baseRoute}/${skyHiId}/file/${fileName}`, {});
  } catch (error) {
    return error;
  }
}

/**
* @description OPN File Content API Call
* @param {string} fileURL URL related to the file's content requested
* @param {string} fileName File Name of file pertaining to the details requested
* @returns {object} API Response with OPN Files
*/
export async function getOPNFileContent(fileURL) {
  try {
    return await getFileURLContent(fileURL);
  } catch (error) {
    return error;
  }
}

/**
* @description Starts the OPN Orchestration process.
* @returns Status code so UI can show a toast
*/
export async function opnOrchestration() {
  return postAPIRequest(`${baseRoute}/orchestration`, {});
}
