import React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  // useMsal,
} from '@azure/msal-react';
import ProtectedRoute from 'modules/Authentication/components/ProtectedRoute';
import AuthPageContainer from 'modules/Authentication/components/AuthPageContainer';
import SignInPage from 'modules/pages/components/SignInPage/SignInPage';
import './App.css';

/**
 * If a user is authenticated the MainPage component is rendered.
 * Otherwise a message indicating a user is not authenticated is rendered.
 * @return {object}
 */
function MainContent() {
  return (
    <div className="App" data-testid="appComponent">
      <AuthenticatedTemplate>
        <ProtectedRoute />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <SignInPage />
      </UnauthenticatedTemplate>

    </div>
  );
}

/**
 * exports main content
 * @return {object}
 */
export default function App() {
  return (
    <AuthPageContainer props={<MainContent />} />
  );
}
