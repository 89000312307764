import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { ModulesBoxStyle, ResyncModulesBoxStyle } from 'modules/common/components/CustomTables/TableFunctions/StyleFunctions';

export function SyncHubDialog({ openSyncHub, handleCloseSyncHub, callSyncHubAPI }) {
  return (
    <Dialog
      open={openSyncHub}
      onClose={handleCloseSyncHub}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Push the latest updates to this hub now?
      </DialogTitle>
      <DialogActions>
        <Button variant="secondary" onClick={handleCloseSyncHub}>Cancel</Button>
        <Button variant="secondary" onClick={callSyncHubAPI} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SyncHubDialog.propTypes = {
  openSyncHub: PropTypes.bool.isRequired,
  handleCloseSyncHub: PropTypes.func.isRequired,
  callSyncHubAPI: PropTypes.func.isRequired,
};

export function DisableHubDialog({ openDisableHub, handleCloseDisableHub, callDisableHubAPI }) {
  return (
    <Dialog
      open={openDisableHub}
      onClose={handleCloseDisableHub}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Stop Sending updates to this HUB?
      </DialogTitle>
      <DialogActions>
        <Button variant="secondary" onClick={handleCloseDisableHub}>Cancel</Button>
        <Button variant="secondary" onClick={callDisableHubAPI} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DisableHubDialog.propTypes = {
  openDisableHub: PropTypes.bool.isRequired,
  handleCloseDisableHub: PropTypes.func.isRequired,
  callDisableHubAPI: PropTypes.func.isRequired,
};

export function UpdateHubDialog({
  openUpdateHub, handleCloseUpdateHub, callUpdateHubAPI, rowDescription,
}) {
  const description = useRef();
  return (
    <Dialog
      open={openUpdateHub}
      onClose={handleCloseUpdateHub}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Update Hub Details
      </DialogTitle>
      <DialogContent>
        <TextField id="description" defaultValue={rowDescription} inputRef={description} label="Hub Description:" variant="outlined" fullWidth margin="normal" />
      </DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={handleCloseUpdateHub}>Cancel</Button>
        <Button variant="secondary" onClick={() => { callUpdateHubAPI(description.current.value); }} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UpdateHubDialog.propTypes = {
  openUpdateHub: PropTypes.bool.isRequired,
  handleCloseUpdateHub: PropTypes.func.isRequired,
  callUpdateHubAPI: PropTypes.func.isRequired,
  rowDescription: PropTypes.string.isRequired,
};

export function DeleteHubDialog({ openDeleteHub, handleCloseDeleteHub, callDeleteHubAPI }) {
  return (
    <Dialog
      open={openDeleteHub}
      onClose={handleCloseDeleteHub}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to delete the hub?
      </DialogTitle>
      <DialogActions>
        <Button variant="secondary" onClick={handleCloseDeleteHub}>Cancel</Button>
        <Button variant="secondary" onClick={callDeleteHubAPI} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteHubDialog.propTypes = {
  openDeleteHub: PropTypes.bool.isRequired,
  handleCloseDeleteHub: PropTypes.func.isRequired,
  callDeleteHubAPI: PropTypes.func.isRequired,
};

export function ModalSample({ openModal, handleCloseModal }) {
  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModulesBoxStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Sample Test Modal
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Testing Set As Primary Hub
        </Typography>
      </Box>
    </Modal>
  );
}

ModalSample.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export function ResyncHubsModal({ openResyncModal, handleCloseResyncModal }) {
  return (
    <Modal
      open={openResyncModal}
      onClose={handleCloseResyncModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ResyncModulesBoxStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Sample Modal For Resync Trigger
        </Typography>
      </Box>
    </Modal>
  );
}

ResyncHubsModal.propTypes = {
  openResyncModal: PropTypes.bool.isRequired,
  handleCloseResyncModal: PropTypes.func.isRequired,
};
