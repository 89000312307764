import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Grid, Button, TextField, FormControl,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { LoadingButton } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import SendIcon from '@mui/icons-material/Send';
import './SearchForm.css';
import * as OpnService from '../../ApiServices/OPNService/OpnApi';

export default function SearchForm(props) {
  const {
    setSearchValues,
    iniSearchValues,
    searchValues,
    OpnListApiCallParams,
    setData,
    getOPNs,
    setLoadingData,
    setApiError,
    setApiSuccess,
    setShowToast,
    setTotalRecords,
  } = props;

  const cookies = new Cookies();
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  // Search Values
  const [partNumber, setPartNumber] = cookies.get('opnId') === undefined ? useState('') : useState(cookies.get('opnId'));
  const [partFamilyName, setPartFamilyName] = cookies.get('partFamilyName') === undefined ? useState('') : useState(cookies.get('partFamilyName'));
  const [basePartName, setBasePartName] = cookies.get('basePartName') === undefined ? useState('') : useState(cookies.get('basePartName'));
  const [dateFrom, setDateFrom] = cookies.get('createdFrom') === undefined ? React.useState((dayjs().subtract(30, 'day'))) : React.useState((dayjs(cookies.get('createdFrom'))));
  const [dateTo, setDateTo] = cookies.get('createdTo') === undefined ? React.useState(dayjs()) : React.useState(dayjs(cookies.get('createdTo')));

  const validate = () => {
    const temp = {};
    return Object.values(temp).every((x) => x === '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      // Save the filter values in a Cookie.
      if (partNumber !== undefined) {
        cookies.set('opnId', partNumber, { path: '/' });
        searchValues.opnId = partNumber.toString();
      }
      if (partFamilyName !== undefined) {
        cookies.set('partFamilyName', partFamilyName, { path: '/' });
        searchValues.partFamilyName = partFamilyName.toString();
      }
      if (basePartName !== undefined) {
        cookies.set('basePartName', basePartName, { path: '/' });
        searchValues.basePartName = basePartName.toString();
      }
      if (dateFrom !== undefined && dateFrom !== '') {
        cookies.set('createdFrom', dateFrom, { path: '/' });
        const date = dateFrom.toJSON();
        searchValues.dateFrom = date;
      }
      if (dateTo !== undefined && dateTo !== '') {
        cookies.set('createdTo', dateTo, { path: '/' });
        const date = dateTo.toJSON();
        searchValues.dateTo = date;
      }

      OpnListApiCallParams.filterByFields = JSON.stringify(searchValues);
      const opns = getOPNs();
      opns?.then((response) => {
        if (response.data) {
          setData(response.data.data);
          setTotalRecords(response.data.totalRecords);
          setLoadingData(false);
          setApiError('');
          setApiSuccess('Filters as applied');
          setShowToast(true);
        }
      });
      setLoading(false);
    }
  };

  const resetSearchForm = () => {
    // Remove the filter cookies.
    cookies.remove('opnId', { path: '/' });
    cookies.remove('partFamilyName', { path: '/' });
    cookies.remove('basePartName', { path: '/' });
    cookies.remove('createdFrom', { path: '/' });
    cookies.remove('createdTo', { path: '/' });
    setDateFrom(dayjs().subtract(30, 'day'));
    setDateTo(dayjs());
    setPartNumber('');
    setPartFamilyName('');
    setBasePartName('');
    iniSearchValues.opnId = '';
    iniSearchValues.partFamilyName = '';
    iniSearchValues.basePartName = '';
    setSearchValues(iniSearchValues);
  };
  // OPN API Call Default Variables
  const OpnApiCallParams = {
    pageIdx: 0,
    itemsPerPage: 0,
    includePartStepLogs: false,
    filterByFields: '',
    sort: '',
  };
  // Autocomplete text fields.
  useEffect(() => {
    const searchFieldValues = OpnService.getOPNList(
      OpnApiCallParams.pageIdx,
      OpnApiCallParams.itemsPerPage,
      OpnApiCallParams.filterByFields,
      OpnListApiCallParams.sort,
      OpnListApiCallParams.includePartStepLogs,
    );
    searchFieldValues?.then((response) => {
      if (!response.data) {
        setApiError(response.toString());
        setShowToast(true);
      } else {
        setInputValues(response.data.data);
        // setTotalRecords(response.data.totalRecords);
        setLoadingData(false);
      }
    });
  }, []);

  const opnIds = [];
  const partFamily = [];
  const basePart = [];
  inputValues.map((item) => opnIds.push(item.opnId));
  inputValues.map((item) => partFamily.push(item.partFamilyName));
  inputValues.map((item) => basePart.push(item.basePartName));

  // This functions are out of the HTML otherwise will have the:
  // "Can't perform a React state update on an unmounted component" Error
  const onChangePartNumber = (e) => {
    setPartNumber(e.target.value);
  };
  const onAutoSelectedPartNumberChange = (e) => {
    setPartNumber(e.target.textContent);
  };
  const onChangeFamilyName = (e) => {
    setPartFamilyName(e.target.value);
  };
  const onAutoSelectedFamilyNameChange = (e) => {
    setPartFamilyName(e.target.textContent);
  };
  const onChangeBasePart = (e) => {
    setBasePartName(e.target.value);
  };
  const onAutoSelectedBasePartChange = (e) => {
    setBasePartName(e.target.textContent);
  };

  return (
    <div id="searchbox">
      <form onSubmit={handleSubmit}>
        <FormControl>
          <Grid container spacing={6} rowSpacing={1} justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={4}>
              <Autocomplete
                freeSolo
                id="opnId"
                disableClearable
                options={opnIds.filter((item, index) => opnIds.indexOf(item) === index).sort()}
                renderInput={(params) => (
                  <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="Part Num"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                    placeholder="Si5386A-E14258-GM"
                    onInput={onChangePartNumber}
                  />
                )}
                inputProps={{ tabIndex: '3' }}
                name="opnId"
                onChange={onAutoSelectedPartNumberChange}
                value={partNumber}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                freeSolo
                id="partFamilyName"
                disableClearable
                options={partFamily.filter((item, index) => partFamily.indexOf(item) === index
                  && item !== null).sort()}
                renderInput={(params) => (
                  <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="Part Family"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                    placeholder="SI5380"
                    onInput={onChangeFamilyName}
                  />
                )}
                value={partFamilyName}
                onChange={onAutoSelectedFamilyNameChange}
                inputProps={{ tabIndex: '6' }}
                name="partFamilyName"
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                freeSolo
                id="basePartName"
                disableClearable
                options={basePart.filter((item, index) => basePart.indexOf(item) === index
                  && item !== null).sort()}
                renderInput={(params) => (
                  <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="Base Part"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                    placeholder="SI5386-E-ZXM"
                    onInput={onChangeBasePart}
                  />
                )}
                value={basePartName}
                onChange={onAutoSelectedBasePartChange}
                inputProps={{ tabIndex: '8' }}
                name="basePartName"
              />
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider item dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  disableFuture
                  label="Created From"
                  name="dateFrom"
                  minDate={dayjs('2000-01-01')}
                  value={dateFrom}
                  onChange={(newValue) => {
                    setDateFrom(newValue);
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} name="dateFrom" />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  disableFuture
                  label="Created to"
                  name="createdTo"
                  minDate={dayjs('2000-01-01')}
                  value={dateTo}
                  onChange={(newValue) => {
                    setDateTo(newValue);
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} name="createdTo" />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={4} alignItems="flex-end">
              <div className="buttonBox">
                <LoadingButton
                  type="submit"
                  endIcon={<SendIcon />}
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                >
                  Filter
                </LoadingButton>
                <Button type="reset" variant="outlined" size="large" text="Reset" onClick={resetSearchForm}> Reset </Button>
              </div>
            </Grid>
          </Grid>
        </FormControl>
      </form>
    </div>
  );
}

SearchForm.propTypes = {
  searchValues: PropTypes.instanceOf(Object).isRequired,
  setSearchValues: PropTypes.func.isRequired,
  iniSearchValues: PropTypes.instanceOf(Object).isRequired,
  getOPNs: PropTypes.func.isRequired,
  OpnListApiCallParams: PropTypes.instanceOf(Object).isRequired,
  setData: PropTypes.func.isRequired,
  setLoadingData: PropTypes.func.isRequired,
  setApiError: PropTypes.func.isRequired,
  setApiSuccess: PropTypes.func.isRequired,
  setShowToast: PropTypes.func.isRequired,
  setTotalRecords: PropTypes.func.isRequired,
};
