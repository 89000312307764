import React from 'react';
import Grid from '@mui/material/Grid';
import {
  Route, BrowserRouter as Router, Routes, Navigate,
} from 'react-router-dom';
import { Header, Footer, Sidebar } from 'modules/common/components/index';
import Factories from '../Factories/Factories';
import FactoryHubs from '../FactoryHubs/FactoryHubs';
import Requests from '../Requests/Requests';

function MainPage() {
  return (
    <div className="mainPage" data-testid="mainPageComponent">
      <Header />

      <Grid container>
        <Router>
          <Grid item xs={3} sm={2} md={2} lg={1}>
            <Sidebar />
          </Grid>
          <Grid item xs={9} sm={10} md={10} lg={11}>
            <Routes>
              <Route path="/requests" element={<Requests />} />
              <Route path="/factoryhubs" element={<FactoryHubs />} />
              <Route path="/factories" element={<Factories />} />
              <Route path="/" element={<Navigate to="/requests" />} />
            </Routes>
          </Grid>
        </Router>
      </Grid>

      <Footer />
    </div>
  );
}

export default MainPage;
