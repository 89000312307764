import * as React from 'react';
import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import Tooltip from '@mui/material/Tooltip';
import { convertToLocalDate } from '../../../../utils/utils';

const OSATHeadCells = [
  {
    field: 'id',
    numeric: false,
    disablePadding: true,
    headerName: 'OSAT id',
    description: 'OSAT id',
    sortable: true,
    minWidth: 330,
    renderCell: (params) => (
      <Tooltip title={params.row.id}>
        <span className="table-cell-trucate">{params.row.id}</span>
      </Tooltip>
    ),
  },
  {
    field: 'description',
    numeric: false,
    disablePadding: true,
    headerName: 'Description',
    description: 'Description',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.row.description}>
        <span className="table-cell-trucate">{params.row.description}</span>
      </Tooltip>
    ),
  },
  {
    field: 'name',
    numeric: false,
    disablePadding: false,
    headerName: 'Location',
    description: 'Location',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.row.name}>
        <span className="table-cell-trucate">{params.row.name}</span>
      </Tooltip>
    ),
  },
  {
    field: 'lastConnectionTime',
    numeric: true,
    disablePadding: false,
    headerName: 'Last Connection',
    description: 'Last Connection',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <div>
        {(new Date().getDate() - new Date(params.row.lastConnectionTime).getDate()) >= 1 ? <WarningIcon style={{ color: '#F2AD06' }} /> : null}
        <Tooltip title={convertToLocalDate(params.row.lastConnectionTime)}>
          <span className="table-cell-trucate">{convertToLocalDate(params.row.lastConnectionTime)}</span>
        </Tooltip>
      </div>
    ),
  },
  {
    field: 'serverAddress',
    numeric: true,
    disablePadding: false,
    headerName: 'FTP Server',
    description: 'FTP Server',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.row.serverAddress}>
        <span className="table-cell-trucate">{params.row.serverAddress}</span>
      </Tooltip>
    ),
  },
  {
    field: 'rootFtpDir',
    numeric: true,
    disablePadding: false,
    headerName: 'FTP Root folder',
    description: 'FTP Root folder',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.row.rootFtpDir}>
        <span className="table-cell-trucate">{params.row.rootFtpDir}</span>
      </Tooltip>
    ),
  },
  {
    field: 'ftpPort',
    numeric: true,
    disablePadding: false,
    headerName: 'FTP Port',
    description: 'FTP Port',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.row.ftpPort}>
        <span className="table-cell-trucate">{params.row.ftpPort}</span>
      </Tooltip>
    ),
  },
  {
    field: 'reservedHubIpPrimary',
    numeric: true,
    disablePadding: false,
    headerName: 'Primary HUB IP',
    description: 'Primary HUB IP',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.row.reservedHubIpPrimary}>
        <span className="table-cell-trucate">{params.row.reservedHubIpPrimary}</span>
      </Tooltip>
    ),
  },
  {
    field: 'reservedHubIpBackup',
    numeric: true,
    disabledPadding: false,
    headerName: 'Backup Hub IP',
    description: 'Backup HUB IP',
    sortable: true,
    minWidth: 250,
    renderCell: (params) => (
      <Tooltip title={params.row.reservedHubIpBackup}>
        <span className="table-cell-trucate">{params.row.reservedHubIpBackup}</span>
      </Tooltip>
    ),
  },
];

export default OSATHeadCells;
