import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

function SubPanelEmptyDataComponent() {
  return (
    <TableRow>
      <TableCell
        colSpan={12}
        style={{ textAlign: 'center', height: '140px', fontSize: '24px' }}
      >
        History is not available in SkyHI for OPNs generated prior to the SkyHI go live date.
      </TableCell>
    </TableRow>
  );
}

export default SubPanelEmptyDataComponent;
