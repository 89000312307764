import * as React from 'react';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import Tooltip from '@mui/material/Tooltip';
import { convertToLocalDate } from '../../../../utils/utils';

const FactoryHubsHeadCells = [
  {
    field: 'id',
    numeric: true,
    disablePadding: true,
    headerName: 'Hub ID',
    description: 'Hub ID',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.row.id}>
        <span className="table-cell-trucate">{params.row.id}</span>
      </Tooltip>
    ),
  },
  {
    field: 'name',
    numeric: false,
    disablePadding: false,
    headerName: 'Hub Name',
    description: 'Hub Name',
    sortable: true,
    minWidth: 160,
    renderCell: (params) => (
      <Tooltip title={params.row.name}>
        <span className="table-cell-trucate">{params.row.name}</span>
      </Tooltip>
    ),
  },
  {
    field: 'description',
    numeric: false,
    disablePadding: false,
    headerName: 'Description',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.row.description}>
        <span className="table-cell-trucate">{params.row.description}</span>
      </Tooltip>
    ),
  },
  {
    field: 'osat.name',
    numeric: false,
    disablePadding: false,
    headerName: 'Location',
    description: 'Location',
    sortable: true,
    minWidth: 120,
    valueGetter: (params) => params.row.osat?.name,
    renderCell: (params) => (
      <Tooltip title={params.row.osat?.name}>
        <span className="table-cell-trucate">{params.row.osat?.name}</span>
      </Tooltip>
    ),
  },
  {
    field: 'lastPushedCommit',
    numeric: true,
    disablePadding: false,
    headerName: 'Last Pushed Commit',
    sortable: true,
    minWidth: 300,
    renderCell: (params) => (
      <div>
        {params.row.lastPushedCommit !== params.row.currentCommit ? <SyncProblemIcon style={{ color: '#F2AD06' }} /> : null}
        <Tooltip title={params.row.lastPushedCommit}>
          <span className="table-cell-trucate">{params.row.lastPushedCommit}</span>
        </Tooltip>
      </div>
    ),
  },
  {
    field: 'lastReportedCommit',
    numeric: true,
    disablePadding: false,
    headerName: 'Last Confirmed Commit',
    sortable: true,
    minWidth: 300,
    renderCell: (params) => (
      <div>
        {params.row.lastReportedCommit !== params.row.currentCommit ? <SyncProblemIcon style={{ color: '#F2AD06' }} /> : null}
        <Tooltip title={params.row.lastReportedCommit}>
          <span className="table-cell-trucate">{params.row.lastReportedCommit}</span>
        </Tooltip>
      </div>
    ),
  },
  {
    field: 'lastCheckin',
    numeric: true,
    disablePadding: false,
    headerName: 'Last FTP Connect',
    sortable: true,
    minWidth: 180,
    renderCell: (params) => (
      <div>{convertToLocalDate(params.row.lastCheckin)}</div>
    ),
  },
  {
    field: 'primaryBackup',
    numeric: true,
    disablePadding: false,
    headerName: 'Primary/Backup',
    description: 'Primary/Backup',
    sortable: true,
    minWidth: 120,
    valueGetter: (params) => {
      if (params.row.ip === params.row.osat.reservedHubIpPrimary) {
        return 'Primary';
      }
      if (params.row.ip === params.row.osat.reservedHubIpBackup) {
        return 'Backup';
      }
      return 'Unlisted';
    },
  },
  {
    field: 'activeStatus',
    numeric: false,
    disablePadding: false,
    headerName: 'Status',
    description: 'Status',
    sortable: true,
    minWidth: 120,
    type: 'string',
    valueGetter: (params) => {
      const value = params.row.activeStatus ? 'ONLINE' : 'OFFLINE';
      return value;
    },
  },
  {
    field: 'enabled',
    numeric: false,
    disabledPadding: false,
    headerName: 'Enabled',
    description: 'Enabled',
    sortable: true,
    minWidth: 120,
    valueGetter: (params) => {
      const value = params.row.enabled ? 'ENABLE' : 'DISABLE';
      return value;
    },
  },
  {
    field: 'healthy',
    numeric: false,
    disabledPadding: false,
    headerName: 'Health Message',
    description: 'Health Message',
    sortable: true,
    minWidth: 120,
    valueGetter: (params) => {
      const value = params.row.healthy ? 'HEALTHY' : 'UNHEALTHY';
      return value;
    },
  },
  {
    field: 'syncStatus',
    numeric: false,
    disabledPadding: false,
    headerName: 'Sync Status',
    description: 'Sync Status',
    sortable: true,
    minWidth: 120,
  },
  {
    field: 'syncMessage',
    numeric: false,
    disabledPadding: false,
    headerName: 'Sync Message',
    description: 'Sync Message',
    sortable: true,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params.row.syncMessage}>
        <span className="table-cell-trucate">{params.row.syncMessage}</span>
      </Tooltip>
    ),
  },
  {
    field: 'ip',
    numeric: true,
    disablePadding: false,
    headerName: 'IP Address',
    description: 'IP Address',
    sortable: true,
    minWidth: 120,
  },
  {
    field: 'macAddress',
    numeric: true,
    disablePadding: false,
    headerName: 'Mac Address',
    description: 'Mac Address',
    sortable: true,
    minWidth: 120,
    renderCell: (params) => (
      <Tooltip title={params.row.macAddress}>
        <span className="table-cell-trucate">{params.row.macAddress}</span>
      </Tooltip>
    ),
  },
];

export default FactoryHubsHeadCells;
