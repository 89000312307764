import axios from 'axios';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, loginRequest } from '../../../../authConfig';
import Profile from '../../../Authentication/components/MSProfile';

const GetAccessToken = async () => {
  const pca = new PublicClientApplication(msalConfig);
  const accounts = pca.getAllAccounts();
  const account = accounts[0];

  const response = await pca.acquireTokenSilent({
    scopes: loginRequest.scopes,
    account,
  });

  return response.accessToken;
};

// API GET Call
async function getAPIRequest(route, params) {
  const accessToken = await GetAccessToken();
  return axios({
    method: 'get',
    url: Profile.REACT_APP_SKYHI_BASE_URL + route,
    params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

// API POST Call
async function postAPIRequest(route, data, params) {
  const accessToken = await GetAccessToken();
  return axios({
    method: 'post',
    url: Profile.REACT_APP_SKYHI_BASE_URL + route,
    data,
    params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

// API PUT Call
async function putAPIRequest(route, data) {
  const accessToken = await GetAccessToken();
  return axios({
    method: 'put',
    url: Profile.REACT_APP_SKYHI_BASE_URL + route,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

// API DELETE Call
async function deleteAPIRequest(route) {
  const accessToken = await GetAccessToken();
  return axios({
    method: 'delete',
    url: Profile.REACT_APP_SKYHI_BASE_URL + route,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

// API Download Files Call to avoid Cors Issue
function getFileURLContent(fileURL) {
  return axios({
    method: 'GET',
    url: fileURL,
    headers: {
      // we should remove this in production
      'Access-Control-Allow-Origin': Profile.REACT_APP_REDIRECT_URI,
    },
  });
}

export {
  getAPIRequest, postAPIRequest, putAPIRequest, deleteAPIRequest, getFileURLContent,
};
