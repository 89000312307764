import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig, loginRequest } from './authConfig';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Profile from './modules/Authentication/components/MSProfile';

/**
  Try to retrieve the environment settings from API otherwise defaults to the env values.
  This has to be done prior to creating the msalProvider instance.
 */
function GetEnvSettings() {
  const [envSetting, setEnvSetting] = useState(false);
  useEffect(() => {
    (async function () {
      let result;
      try {
        result = await (await fetch('/api/GetEnvSettings')).json();
        console.log('Pulled settings from server');
      } catch (error) {
        console.log('Couldn\'t pull settings from server, using local env settings: ', error);
      }
      Profile.SetEnvVariables(result);
      // set msalConfig settings.
      msalConfig.auth.clientId = Profile.REACT_APP_CLIENT_ID;
      msalConfig.auth.authority = Profile.REACT_APP_AUTHORITY;
      msalConfig.auth.redirectUri = Profile.REACT_APP_REDIRECT_URI;
      loginRequest.scopes.push(`api://${Profile.REACT_APP_CLIENT_ID}/user_impersonation`);
      setEnvSetting(true);
    }());
  }, []);

  if (envSetting) {
    return (
      <MsalProvider instance={new PublicClientApplication(msalConfig)}>
        <App />
      </MsalProvider>
    );
  }
  return (<div />);
}

ReactDOM.render(
  <React.StrictMode>
    <GetEnvSettings />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
