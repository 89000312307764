import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { loginRequest } from 'authConfig';
import Profile from './MSProfile';

/**
 * Renders component passed in and finds user groups
 * determines on whether or not a user is authenticated
 * @param {object} props
 * @return {Component}
 */
function AuthPageContainer({ props }) {
  const [authorized, setAuthorized] = useState(false);
  const { instance } = useMsal();
  // const { children } = props;

  // useEffect used for when user refreshes
  useEffect(async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: instance.getActiveAccount()
          ? instance.getActiveAccount : instance.getAllAccounts(),
      });
      console.log('RESPONSE', response);
      if (response.accessToken) {
        console.log('AuthPageContainerACCESSTOKEN', response.accessToken);
      }

      if (!response.idTokenClaims.groups) {
        setAuthorized(false);
      } else {
        Profile.SetUserRoles(response.idTokenClaims.groups);
        setAuthorized(Profile.isAuthorizedUser);
      }
    } catch (err) {
      console.log(err);
    }

    console.log('is authorized because of group:', authorized);
  }, [instance]);

  // useEffect used on LOGIN SUCCESS
  useEffect(() => {
    // This will be run on component mount
    const callbackId = instance.addEventCallback((message) => {
    // This will be run every time an event is emitted after registering this callback
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const result = message.payload;
        console.log('NEW RESULT', result);
        if (result.accessToken) {
          console.log('AuthPageContainerACCESSTOKEN - 2', result.accessToken);
        }
        if (result.idTokenClaims.groups) {
          Profile.SetUserRoles(result.idTokenClaims.groups);
        }
      }
    });

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return (
    <>
      {/* <div style={divStyle}>
        { isAuthenticated ? <SignOutButton /> : <SignInButton /> }
      </div> */}

      { props }
    </>
  );
}

AuthPageContainer.propTypes = {
  props: PropTypes.element.isRequired,
};

export default AuthPageContainer;
