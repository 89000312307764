import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SubPanelEmptyDataComponent from '../../EmptyTable/SubPanelEmptyDataComponent';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E5E5E5',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F9F9F9',
    color: theme.palette.common.black,
  },
}));

function HistoryTable(props) {
  const { selectedInfoSteps } = props;
  const skyIDSepsRows = selectedInfoSteps || [];

  HistoryTable.propTypes = {
    selectedInfoSteps: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  };

  return (
    <div className="HistoryTable" data-testid="HistoryTableComponent">
      {/* <EnhancedTableToolbar numselected={1} itemSelection={partNum[0]} /> */}
      <TableContainer
        component={Paper}
        sx={{
          height: 300,
          margin: 'auto',
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Step</StyledTableCell>
              <StyledTableCell align="left">Date</StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left">Note</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skyIDSepsRows.map((row) => (
              <StyledTableRow
                key={row.opnStepName}
                hover
               // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="left">
                  {row.opnStepName}
                </TableCell>
                <TableCell align="left" width="20%">{row.opnStepUpdatedTs}</TableCell>
                <TableCell align="left" width="20%">{row.opnStepStatus}</TableCell>
                <TableCell align="left" width="30%">{row.opnStepStatusDetails}</TableCell>
              </StyledTableRow>
            ))}
            { skyIDSepsRows.length === 0 ? (
              <SubPanelEmptyDataComponent />
            ) : null }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default HistoryTable;
