import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';
import MainPage from 'modules/pages/components/MainPage/MainPage';
import UnauthorizedPage from 'modules/pages/components/UnauthorizedPage/UnauthorizedPage';
import Profile from './MSProfile';

function ProtectedRoute() {
  const { instance } = useMsal();
  const [authorized, setAuthorized] = useState(false);

  // useEffect used for when user refreshes
  useEffect(async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: instance.getActiveAccount()
          ? instance.getActiveAccount : instance.getAllAccounts(),
      });
      console.log('RESPONSE', response);
      if (!response.idTokenClaims.groups) {
        setAuthorized(false);
      } else {
        Profile.SetUserRoles(response.idTokenClaims.groups);
        setAuthorized(Profile.isAuthorizedUser);
      }
    } catch (err) {
      console.log(err);
    }

    console.log('is authorized becuse of group:', authorized);
  }, [instance]);
  return (
    authorized ? <MainPage /> : <UnauthorizedPage />
  );
}

export default ProtectedRoute;
