import React from 'react';
import './Footer.css';

/**
 * Returns Footer Content for Main Panel
 * @return {Component}
 */
function Footer() {
  return (
    <div className="footer" data-testid="footerComponent">
      <div className="line"> </div>
      <div className="credits" data-testid="footerText">Property of © Skyworks</div>
    </div>
  );
}

export default Footer;
