import { getAPIRequest, postAPIRequest, putAPIRequest } from '../AxiosApis';

const baseRoute = 'factoryhubs';

/**
* @description Factory Hubs List API Call
* @param {boolean} showDeleted required,
if TRUE Api Response will include deleted Factory Hubs
* @returns {object} API Response with Factory Hubs List
*/
export async function getFactoryHubs(showDeleted) {
  const params = { showDeleted };
  try {
    const response = await getAPIRequest(`${baseRoute}`, params);
    return response;
  } catch (error) {
    return error;
  }
}

/**
* @description Factory Hubs Sync Hub API Call
* @param {string} osatid required,
* @param {string} hubid required,
* @returns {object} API Response returns Status Code
*/
export async function syncHub(hubId, osatId) {
  const params = { hubId, osatId };
  try {
    const response = await postAPIRequest(`${baseRoute}/update`, params);
    return response;
  } catch (error) {
    return error;
  }
}

/**
* @description Factory Hubs Sync All Hubs API Call
* @returns {object} API Response returns Status Code
*/
export async function syncAllHubs() {
  try {
    const response = await postAPIRequest(`${baseRoute}/update`, {});
    return response;
  } catch (error) {
    return error;
  }
}

/*
* @description Factory Hub Update API Call
* @param {string} params required,
* @returns {object} API Update Factory Hub
*/
export async function updateFactoryHub(params) {
  try {
    const response = await putAPIRequest(`${baseRoute}`, params);
    console.log('response: ', response);
    return response;
  } catch (error) {
    console.log('ERROR', error);
    return error;
  }
}
