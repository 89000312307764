import React, { useState } from 'react';
import { Button } from '@mui/material';
import FactoriesTable from 'modules/common/components/CustomTables/FactoriesTable/FactoriesTable';
import FactoryForm from 'modules/common/components/CustomForms/FactoriesForm/FactoryForm';
import FactoryModal from 'modules/common/components/CustomTables/FactoriesTable/FactoriesModal/FactoryModal';
import Profile from 'modules/Authentication/components/MSProfile';
import * as OsatService from 'modules/common/components/ApiServices/OSATService/OsatApi';
import AddIcon from '@mui/icons-material/Add';
import './Factories.css';

/**
 * Returns Factories Content for Main Panel
 * @return {Component}
 */
function Factories() {
  const [openPopup, setOpenPopup] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const initialValues = {
    name: '',
    description: '',
    serverAddress: '',
    ftpProtocol: 'FTP',
    accountName: '',
    ftpPort: 21,
    rootFtpDir: '',
    reservedHubIpPrimary: '',
    reservedHubIpBackup: '',
    forceCreate: false,
  };
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [title, setTitle] = useState('Register OSAT');
  const [selectedOsatID, setSelectedOsatID] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [apiError, setApiError] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [apiSuccess, setApiSuccess] = useState('');

  const addOSAT = () => {
    setOpenPopup(true);
    setValues(initialValues);
    setTitle('Register OSAT');
    setIsUpdate(false);
  };
  const resetForm = () => {
    setValues(initialValues);
    setErrors({});
  };

  // CALL API
  const callCreateOsatAPI = () => {
    const array = OsatService.createOSAT(values);
    array.then((response) => {
      if (!response || response.status !== 201) {
        setApiError(response.response.data.toString());
        setApiSuccess('');
        if (response.response.data.trim() !== '') {
          setShowToast(true);
        }
        setLoading(false);
      } else {
        resetForm();
        setLoading(false);
        setOpenPopup(false);
        setApiError('');
        setApiSuccess(`${response.status.toString()} Create OSAT Success`);
        setShowToast(true);
      }
    });
  };

  const callUpdateOsatAPI = () => {
    const array = OsatService.updateOSAT(values);
    array.then((response) => {
      if (!response || response.status !== 200) {
        setApiError(response.toString());
        setApiSuccess('');
        setShowToast(true);
        setLoading(false);
      } else {
        resetForm();
        setLoading(false);
        setOpenPopup(false);
        setApiError('');
        setApiSuccess(`${response.status.toString()} OSAT Updated Successfully`);
        setShowToast(true);
      }
    });
  };

  return (
    <div className="factories">
      <span className="title" data-testid="header">Factories</span>
      <div>
        <Button variant="outlined" color="primary" size="large" text="Submit" startIcon={<AddIcon />} onClick={() => addOSAT()} disabled={!Profile.isAdminUser}> Add New </Button>
      </div>
      <FactoriesTable
        setOpenPopup={setOpenPopup}
        setIsUpdate={setIsUpdate}
        setValues={setValues}
        initialValues={initialValues}
        setTitle={setTitle}
        setSelectedOsatID={setSelectedOsatID}
        selectedOsatID={selectedOsatID}
        values={values}
        setShowToast={setShowToast}
        showToast={showToast}
        setApiError={setApiError}
        apiError={apiError}
        apiSuccess={apiSuccess}
        setLoadingData={setLoadingData}
        loadingData={loadingData}
      />
      <FactoryModal
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title={title}
      >
        <FactoryForm
          setOpenPopup={setOpenPopup}
          isUpdate={isUpdate}
          setValues={setValues}
          initialValues={initialValues}
          values={values}
          // refreshRequest={refreshRequest}
          callCreateOsatAPI={callCreateOsatAPI}
          callUpdateOsatAPI={callUpdateOsatAPI}
          setErrors={setErrors}
          errors={errors}
          setLoading={setLoading}
          loading={loading}
        />
      </FactoryModal>
    </div>
  );
}

export default Factories;
