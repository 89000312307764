import React from 'react';
import { useMsal } from '@azure/msal-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { loginRequest } from 'authConfig';

const theme = createTheme({
  palette: {
    neutral: {
      main: '#1DA1F2',
      contrastText: '#fff',
    },
  },
});

/**
 * Renders a drop down button with child buttons for
 * logging in with a popup or redirect
 * @return {Component}
 */
export default function SignInButton() {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    if (loginType === 'popup') {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === 'redirect') {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };
  return (
    <div
      variant="primary"
      title="Sign In"
    >
      {/* <button type="button" as="button" onClick={() => handleLogin('popup')}>
        Sign in using Popup
      </button>
      <br /> */}
      <ThemeProvider theme={theme}>
        <Button color="neutral" variant="contained" onClick={() => handleLogin('redirect')}>
          Sign In
        </Button>
      </ThemeProvider>
    </div>
  );
}
