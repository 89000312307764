import React from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useMsal } from '@azure/msal-react';
import LogoutIcon from '@mui/icons-material/Logout';
import { MenuPaperPropsStyle } from 'modules/common/components/CustomTables/TableFunctions/StyleFunctions';
import logo from 'assets/SkyWorksLogo.png';
import './Header.css';

/**
 * Returns Header Content for Main Panel
 * @return {Component}
 */
function Header() {
  // Dialog Modules State Component
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openedMenu = Boolean(anchorEl);

  const handleIconButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openLogOutDialog = () => {
    handleMenuClose();
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };

  const signedInUser = instance.getActiveAccount()
    ? instance.getActiveAccount : instance.getAllAccounts();

  const userName = signedInUser[0]?.name ? signedInUser[0].name : 'User';

  return (
    <div className="header" data-testid="headerComponent">

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openedMenu}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={MenuPaperPropsStyle}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={openLogOutDialog}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>

      <div className="logo">
        <img alt="" src={logo} width="100" />
      </div>
      <Stack spacing={1} className="right-section">
        <div className="user-info">
          <div className="user-icon"><PersonOutlineOutlinedIcon color="primary" onClick={handleIconButtonClick} /></div>
          <div className="username">{userName}</div>
        </div>
        {/* <div className="signOut-button">
          <SignOutButton />
        </div> */}
      </Stack>

    </div>
  );
}

export default Header;
