import { getAPIRequest } from '../AxiosApis';

const baseRoute = 'artifactrepo';

/**
* @description Artifact Repo API Call
* @returns {object} response constains Commit ID and Updated Time Stamp
*/
export async function getCurrentGitCommit() {
  try {
    const response = await getAPIRequest(`${baseRoute}`, {});
    console.log('response: ', response);
    return response;
  } catch (error) {
    console.log('ERROR', error);
    return error;
  }
}
