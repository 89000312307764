import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import * as OpnService from 'modules/common/components/ApiServices/OPNService/OpnApi';
import { DialogContentText } from '@mui/material';
import './OPNRequestDialogs.css';

export default function IngestionDialogBox({ openDialogName, handleClose, runIngestionStep }) {
  let title = '';
  let description = '';
  switch (openDialogName) {
    case OpnService.IngestionStep.OpnFilesIngested:
      title = 'Load Files from HI FE?';
      description = 'This will download the files from HI FE and restart processing the OPN. If the files already exist in skyhi, they will be overwritten.';
      break;
    case OpnService.IngestionStep.TestFileGenerated:
      title = 'Generate test files for testers?';
      description = 'This will generate the test bundles sent to the factory. Unchanged files will be ignored.';
      break;
    case OpnService.IngestionStep.CreatedInSap:
      title = 'Are you sure you want to create this part in SAP?';
      description = 'This will attempt to create the part in the SAP system.';
      break;
    case OpnService.IngestionStep.CreatedInAplm:
      title = 'Are you sure you want to create this part in Agile PLM?';
      description = 'This will attempt to create the part in the Agile PLM system.';
      break;
    default:
      title = 'Unknown Step';
      description = 'Unknown Step';
      break;
  }
  return (
    <Dialog
      open={openDialogName !== ''}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContentText className="dialogContent" id="alert-dialog-description">
        {description}
      </DialogContentText>
      <DialogActions>
        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
        <Button variant="secondary" onClick={() => runIngestionStep(openDialogName)} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

IngestionDialogBox.defaultProps = {
  openDialogName: '',
  handleClose: undefined,
  runIngestionStep: undefined,
};

IngestionDialogBox.propTypes = {
  openDialogName: PropTypes.string,
  handleClose: PropTypes.func,
  runIngestionStep: PropTypes.func,
};
