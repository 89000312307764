import React from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SignInButton from 'modules/Authentication/components/SignInButton';
import logo from 'assets/SkyWorksLogo.png';
import './SignInPage.css';

function SignInPage() {
  return (
    <div className="unAuthorizedPage">
      <div className="unauthorized-message">

        <div className="skyHi-logo">
          <img alt="" src={logo} width="100" />
        </div>

        <div className="lock-icon">
          <LockOutlinedIcon color="primary" />
        </div>

        <div className="unAuth-msg">
          Sorry, please Sign In or Inquire with Administrators access SkyHi.
        </div>

      </div>
      <div className="SignIn-button">
        <SignInButton />
      </div>
    </div>
  );
}

export default SignInPage;
